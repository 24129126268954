import { FaSnapchat, FaInstagram, FaWhatsapp, FaPhoneAlt } from "react-icons/fa"
export const sideNavData = [
    {
        id: 1,
        text: "سناب شات",
        icon: FaSnapchat,
        href: "https://www.snapchat.com/add/hyam.coffe",
    },
    {
        id: 2,
        text: "إنستجرام",
        icon: FaInstagram,
        href: "https://instagram.com/hyam.coffe",
    },
    {
        id: 3,
        text: "واتساب",
        icon: FaWhatsapp,
        href: "https://api.whatsapp.com/send/?phone=966550663505",
    },
    { id: 4, text: "اتصل بنا", icon: FaPhoneAlt, href: "tel:0567274398" },
]
const data = [
    {
        id: 1,
        title: 'القهوة الساخنة',
        image: '../../../img/list/coffe-cup.png',
        selected: true,
        items: [
            {
                id: 1,
                title: 'اسبريسو',
                description: '',
                image: '../../img/food/esprso_10_11zon.jpg',
                price: "6-8",
                calories: 20,
                selected: false,
            },
            {
                id: 2,
                title: 'امريكانو',
                description: '',
                image: '../../img/food/amricano_1_11zon.jpg',
                price: "8-12",
                calories: 225,
                selected: false,
            },
            {
                id: 3,
                title: 'لاتيه',
                description: '',
                image: '../../img/food/latespanish_20_11zon.jpg',
                price: "8-12",
                calories: 148,
                selected: false,
            },
            {
                id: 4,
                title: 'سبانش لاتيه',
                description: '',
                image: '../../img/food/latespanish_20_11zon.jpg',
                price: "8-12",
                calories: 360,
                selected: false,
            },
            {
                id: 5,
                title: 'كابتشينو',
                description: '',
                image: '../../img/food/latespanish_20_11zon.jpg',
                price: "7-12",
                calories: 300,
                selected: false,
            },
            {
                id: 6,
                title: 'ميكاتو',
                description: '',
                image: '../../img/food/maciato-cortado-falt_22_11zon.jpg',
                price: "7-9",
                calories: 244,
                selected: false,
            },
            {
                id: 7,
                title: 'كورتادو',
                description: '',
                image: '../../img/food/maciato-cortado-falt_22_11zon.jpg',
                price: "8-10",
                calories: 15,
                selected: false,
            },
            {
                id: 8,
                title: 'فلات وايت',
                description: '',
                image: '../../img/food/maciato-cortado-falt_22_11zon.jpg',
                price: "10-12",
                calories: 110,
                selected: false,
            },
            {
                id: 9,
                title: 'قهوة فرنسية',
                description: '',
                image: '../../img/items.png',
                price: "8-12",
                calories: 92,
                selected: false,
            },
            {
                id: 10,
                title: 'هوت شوكليت دبل اسبريسو',
                description: '',
                image: '../../img/items.png',
                price: "10-14",
                calories: 170,
                selected: false,
            },
            {
                id: 11,
                title: 'هوت شوكليت',
                description: '',
                image: '../../img/items.png',
                price: "8-10",
                calories: 158,
                selected: false,
            },
            {
                id: 12,
                title: 'موكا ساخن',
                description: '',
                image: '../../img/items.png',
                price: "8-10",
                calories: 126,
                selected: false,
            },
            {
                id: 13,
                title: 'تركي سادة',
                description: '',
                image: '../../img/items.png',
                price: 8,
                calories: 20,
                selected: false,
            },
            {
                id: 14,
                title: 'تركي بالحليب',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 36,
                selected: false,
            },
            {
                id: 15,
                title: 'قهوة سعودية',
                description: '',
                image: '../../img/items.png',
                price: 6,
                calories: 5,
                selected: false,
            },
            {
                id: 16,
                title: 'كرك',
                description: '',
                image: '../../img/items.png',
                price: 6,
                calories: 148,
                selected: false,
            },
            {
                id: 17,
                title: 'نسكافيه',
                description: '',
                image: '../../img/items.png',
                price: 5,
                calories: 180,
                selected: false,
            },
            {
                id: 18,
                title: 'شاي',
                description: '',
                image: '../../img/items.png',
                price: 3,
                calories: 2,
                selected: false,
            },
        ]
    },
    {
        id: 2,
        title: 'القهوة الباردة',
        image: '../../../img/list/bard.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'ايس لاتيه',
                description: '',
                image: '../../img/food/ice-late-spanishice_13_11zon.jpg',
                price: 10,
                calories: 206,
                selected: false,
            },
            {
                id: 2,
                title: 'ايس سبانش لاتيه',
                description: '',
                image: '../../img/food/ice-late-spanishice_13_11zon.jpg',
                price: 10,
                calories: 155,
                selected: false,
            },
            {
                id: 3,
                title: 'ايس بستاشيو لاتيه',
                description: '',
                image: '../../img/food/ice-pistashio_14_11zon.jpg',
                price: 12,
                calories: 360,
                selected: false,
            },
            {
                id: 4,
                title: 'ايس روز لاتيه',
                description: '',
                image: '../../img/food/ice-roz-late_15_11zon.jpg',
                price: 12,
                calories: 206,
                selected: false,
            },
            {
                id: 5,
                title: 'ايس وايت موكا',
                description: '',
                image: '../../img/food/ice-wait-moca_16_11zon.jpg',
                price: 12,
                calories: 323,
                selected: false,
            },
            {
                id: 6,
                title: 'ماتشا',
                description: '',
                image: '../../img/food/matsha.jpg',
                price: 12,
                calories: 213,
                selected: false,
            },
            {
                id: 7,
                title: 'ايس كراميل ميكاتو',
                description: '',
                image: '../../img/food/ice-cramel-maciato_12_11zon.jpg',
                price: 12,
                calories: 240,
                selected: false,
            },
            {
                id: 8,
                title: 'علبة زجاج 250 مل',
                description: '',
                image: '../../img/food/olba250ml_29_11zon.jpg',
                price: 15,
                calories: 255,
                selected: false,
            },
        ]
    },
    {
        id: 3,
        title: 'القهوة المختصة',
        image: '../../../img/list/mo5.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'كولد برو',
                description: '',
                image: '../../img/food/could-pro_8_11zon.jpg',
                price: 16,
                calories: 25,
                selected: false,
            },
            {
                id: 2,
                title: 'كيميكس',
                description: '',
                image: '../../img/food/kimks_17_11zon.jpg',
                price: 12,
                calories: 10,
                selected: false,
            },
            {
                id: 3,
                title: 'v60',
                description: '',
                image: '../../img/food/v60_31_11zon.jpg',
                price: 12,
                calories: 5,
                selected: false,
            },
        ]
    },
    {
        id: 4,
        title: 'الحـلا',
        image: '../../../img/list/soit.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'كيكة النوتيلا',
                description: '',
                image: '../../img/food/notiela.jpg',
                price: 19,
                calories: 147,
                selected: false,
            },
            {
                id: 2,
                title: 'كيكة المانجا',
                description: '',
                image: '../../img/food/kikmonga.jpg',
                price: 17,
                calories: 86,
                selected: false,
            },
            {
                id: 1000,
                title: 'حلا الرمان',
                description: '',
                image: '../../img/food/roman.jpg',
                price: 10,
                calories: 110,
                selected: false,
            },
            {
                id: 4,
                title: 'كيكة سان سبستيان',
                description: '',
                image: '../../img/food/kiksan.jpg',
                price: 19,
                calories: 121,
                selected: false,
            },
            {
                id: 5,
                title: 'فرنش توست',
                description: '',
                image: '../../img/food/frinsh-tost_11_11zon.jpg',
                price: 17,
                calories: 91,
                selected: false,
            },
            {
                id: 6,
                title: 'ميني بان كيك',
                description: '',
                image: '../../img/food/minebankik.png',
                price: "10-15",
                calories: 66,
                selected: false,
            },
            {
                id: 7,
                title: 'وافل',
                description: '',
                image: '../../img/food/wafel_32_11zon.jpg',
                price: 10,
                calories: 291,
                selected: false,
            },
            {
                id: 8,
                title: 'كريب فتشيني',
                description: '',
                image: '../../img/food/krip-fottini_19_11zon.jpg',
                price: 10,
                calories: 216,
                selected: false,
            },
            {
                id: 9,
                title: 'كيكة زعفران',
                description: '',
                image: '../../img/food/zafran-kik_33_11zon.jpg',
                price: 17,
                calories: 387,
                selected: false,
            },
            {
                id: 10,
                title: 'كيكة حليب',
                description: '',
                image: '../../img/food/milk-kik_26_11zon.jpg',
                price: 17,
                calories: 200,
                selected: false,
            },
            {
                id: 11,
                title: 'تشيز كيك توت',
                description: '',
                image: '../../img/food/cheez-kik-all_3_11zon.jpg',
                price: 10,
                calories: 445,
                selected: false,
            },
            {
                id: 12,
                title: 'تشيز كيك فراولة',
                description: '',
                image: '../../img/food/cheez-kik-all_3_11zon.jpg',
                price: 10,
                calories: 0,
                selected: false,
            },
            {
                id: 13,
                title: 'تشيز كيك اللوتس',
                description: '',
                image: '../../img/food/cheez-kik-all_3_11zon.jpg',
                price: 10,
                calories: 480,
                selected: false,
            },
            {
                id: 14,
                title: 'تشيز كيك بستاشيو',
                description: '',
                image: '../../img/food/cheez-kik-all_3_11zon.jpg',
                price: 10,
                calories: 200,
                selected: false,
            },
            {
                id: 15,
                title: ' كوكيز فانيلا',
                description: '',
                image: '../../img/food/coceez-fanila_5_11zon.jpg',
                price: 6,
                calories: 160,
                selected: false,
            },
            {
                id: 16,
                title: 'كوكيز شوكليت',
                description: '',
                image: '../../img/food/coceez-choklet_4_11zon.jpg',
                price: 6,
                calories: 157,
                selected: false,
            },
            {
                id: 17,
                title: 'كوكيز ريد فلفت',
                description: '',
                image: '../../img/food/coceez-redfilfet_6_11zon.jpg',
                price: 6,
                calories: 513,
                selected: false,
            },
            {
                id: 18,
                title: 'اضافة بستاشيو',
                description: '',
                image: '../../img/items.png',
                price: 2,
                calories: 34,
                selected: false,
            },
        ]
    },
    {
        id: 5,
        title: 'مشروبات هيام الخاصة',
        image: '../../../img/list/moheto5a9.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'قهوة اليوم',
                description: '',
                image: '../../img/food/today-coffee_30_11zon.jpg',
                price: "7-9",
                calories: 170,
                selected: false,
            },
            {
                id: 2,
                title: 'سجنتشر',
                description: '',
                image: '../../img/food/sgntshr.jpg',
                price: "14-16",
                calories: 170,
                selected: false,
            },
            {
                id: 3,
                title: 'موهيتو هيام',
                description: 'سفن أب',
                image: '../../img/food/mohito-m5lo6_28_11zon.jpg',
                price: "10-14",
                calories: 165,
                selected: false,
            },
            {
                id: 4,
                title: 'مخلوط هيام ',
                description: 'كودرد',
                image: '../../img/food/mohito-m5lo6_28_11zon.jpg',
                price: "10-14",
                calories: 250,
                selected: false,
            },
            {
                id: 5,
                title: 'دلة هيام',
                description: 'تعادل 5 أكواب',
                image: '../../img/food/8hoh.jpg',
                price: 28,
                calories: 25,
                selected: false,
            },
            // {
            //     id: 6,
            //     title: 'دلة هيام',
            //     description: 'حجم كبير تعادل 10 أكواب',
            //     image: '../../img/food/dlah-hyam_9_11zon.jpg',
            //     price: 70,
            //     calories: 50,
            //     selected: false,
            // },
            {
                id: 7,
                title: 'ايس اسبريسو',
                description: '',
                image: '../../img/items.png',
                price: 10,
                calories: 20,
                selected: false,
            },
        ]
    },
    {
        id: 6,
        title: 'المشروبات المثلجة',
        image: '../../../img/list/iced-coffee.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'ميلك شيك موكا',
                description: '',
                image: '../../img/food/milk-chek-moca_23_11zon.jpg',
                price: 12,
                calories: 250,
                selected: false,
            },
            {
                id: 2,
                title: 'ميلك شيك شوكليت',
                description: '',
                image: '../../img/food/milk-chek-moca_23_11zon.jpg',
                price: 12,
                calories: 290,
                selected: false,
            },
            {
                id: 3,
                title: 'ميلك شيك سنيكرز',
                description: '',
                image: '../../img/food/milk-chek-snicres_25_11zon.jpg',
                price: 14,
                calories: 270,
                selected: false,
            },
            {
                id: 4,
                title: 'ملك شيك اوريو',
                description: '',
                image: '../../img/food/milk-chek-orio_24_11zon.jpg',
                price: 14,
                calories: 110,
                selected: false,
            },
        ]
    },
    {
        id: 7,
        title: 'المشروبات الباردة',
        image: '../../../img/list/drink.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'كودرد نكهه',
                description: 'متوفر جميع النكهات',
                image: '../../img/food/kodred-mohito-nkhah_18_11zon.jpg',
                price: "8-12",
                calories: 210,
                selected: false,
            },
            {
                id: 2,
                title: 'موهيتو نكهه',
                description: 'متوفر جميع النكهات',
                image: '../../img/food/kodred-mohito-nkhah_18_11zon.jpg',
                price: "8-12",
                calories: 133,
                selected: false,
            },
            {
                id: 3,
                title: 'عصير برتقال',
                description: '',
                image: '../../img/food/bortoqal_2_11zon.jpg',
                price: "7-10",
                calories: 45,
                selected: false,
            },
            {
                id: 4,
                title: 'ليمون نعناع',
                description: '',
                image: '../../img/food/lemoon_21_11zon.jpg',
                price: "7-10",
                calories: 40,
                selected: false,
            },
            {
                id: 5,
                title: 'مياة معدنية',
                description: '',
                image: '../../img/items.png',
                price: 1,
                calories: 0,
                selected: false,
            },
        ]
    },
    {
        id: 8,
        title: 'إضافات القهوة',
        image: '../../../img/list/additives.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'اسبريسو',
                description: 'إضافة على القهوة الباردة',
                image: '../../img/items.png',
                price: 4,
                calories: 20,
                selected: false,
            },
            {
                id: 2,
                title: 'كريمة',
                description: 'إضافة على القهوة الباردة',
                image: '../../img/items.png',
                price: 2,
                calories: 72,
                selected: false,
            },
            {
                id: 3,
                title: 'كراميل',
                description: 'إضافة على القهوة الباردة',
                image: '../../img/items.png',
                price: 1,
                calories: 0,
                selected: false,
            },
            {
                id: 4,
                title: 'بندق',
                description: 'إضافة على القهوة الباردة',
                image: '../../img/items.png',
                price: 1,
                calories: 26,
                selected: false,
            },
        ]
    },
    {
        id: 9,
        title: 'البوكسات',
        image: '../../../img/list/box.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'بوكس القهوة',
                description: 'حجم كبير عدد 12 علبة',
                image: '../../img/food/coffee-box_7_11zon.jpg',
                price: 140,
                calories: 60,
                selected: false,
            },
            {
                id: 2,
                title: 'بوكس القهوة',
                description: 'حجم صغير عدد 8 علبة',
                image: '../../img/food/coffee-box_7_11zon.jpg',
                price: 95,
                calories: 40,
                selected: false,
            },
            {
                id: 3,
                title: 'بوكس موهيتو',
                description: 'حجم كبير عدد 12 علبة',
                image: '../../img/food/mohito-box_27_11zon.jpg',
                price: 130,
                calories: 1596,
                selected: false,
            },
            {
                id: 4,
                title: 'بوكس موهيتو',
                description: 'حجم صغير عدد 6 علبة',
                image: '../../img/food/mohito-box_27_11zon.jpg',
                price: 70,
                calories: 798,
                selected: false,
            },
        ]
    },
    {
        id: 10,
        title: 'المأكولات',
        image: '../../../img/list/croissant.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'ساندويتش لبنه وعسل',
                description: '',
                image: '../../img/food/makolat.jpg',
                price: 10,
                calories: 319,
                selected: false,
            },
            {
                id: 2,
                title: 'ساندويتش لبنه وزعتر',
                description: '',
                image: '../../img/food/makolat.jpg',
                price: 10,
                calories: 324,
                selected: false,
            },
            {
                id: 3,
                title: 'ساندويتش لبنه',
                description: '',
                image: '../../img/food/makolat.jpg',
                price: 10,
                calories: 77,
                selected: false,
            },
            {
                id: 4,
                title: 'ساندوتش نوتلا',
                description: '',
                image: '../../img/food/makolat.jpg',
                price: 10,
                calories: 115,
                selected: false,
            },
            {
                id: 5,
                title: 'كروسان جبنة',
                description: '',
                image: '../../img/items.png',
                price: 7,
                calories: 240,
                selected: false,
            },
            {
                id: 6,
                title: 'دونات صوص',
                description: '',
                image: '../../img/items.png',
                price: 6,
                calories: 307,
                selected: false,
            },
            {
                id: 7,
                title: 'دونات سكر',
                description: '',
                image: '../../img/items.png',
                price: 4,
                calories: 192,
                selected: false,
            },
        ]
    },
]

export default data