import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination } from "swiper"
import Item from "./Item"

const Items = ({
  pagination,
  close,
  categories,
  isSubmitted,
  filteredItems,
  setSwiper
}) => {
  return (
    <Swiper
      loop={true}
      autoHeight={true}
      pagination={pagination}
      speed={500}
      modules={[Pagination]}
      onSwiper={(swiper) => setSwiper(swiper)}
      onSlideChange={() => {
        const activeCat = document.querySelector(
          ".swiper-pagination-bullet-active"
        )
         document.documentElement.scrollTop = 0; 
        activeCat?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        })
        close()
      }}
    >
      {categories.map((category, i) => (
        <SwiperSlide key={i}>
          <div className="relative space-y-2 flex flex-col justify-center overflow-hidden items-center animateItems">
            <div className="w-full p-4 flex flex-col gap-2 items-center bg-white dark:bg-gray-700">
              {isSubmitted ? (
                filteredItems.length === 0 ? (
                  <p className="text-center w-full text-3xl font-bold text-gray-600 mt-20 dark:text-gray-200">
                    لا يوجد نتائج
                  </p>
                ) : (
                  filteredItems.map((item, index) => (
                    <Item item={item} key={index} />
                  ))
                )
              ) : (
                category.items?.map((item, index) => (
                  <Item item={item} key={index} />
                ))
              )}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Items
